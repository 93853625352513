<template>
    <div>

        <div class="container">
            <edit api="coupons" title="coupon" :id="id" :fs="couponFields" back="/admin/coupons" @saved="saved"></edit>
        </div>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import { couponFields } from "@/config";
export default {
    props: ["id"],
    data() {
        return {
            couponFields: couponFields
        };
    },
    components: {
        Edit,
    },
    methods: {
        saved() {
            this.$router.push("/admin/coupons");
        }
    }
};
</script>